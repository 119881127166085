<template>
<div class="div-oftaHistory">
  <br>
  <Title2
    title="HISTORIAL GENERAL DEL PACIENTE"
    label="Datos generales del paciente"
  />
  <h3>{{idappointment}}</h3>
  <history/>
</div>
</template>

<script>
import history from '../../components/HistoryOftaComponent';
import Title2 from '../../components/Title2';

export default {
  data() {
    return {
      idappointment: null,
      data: [],
    };
  },
  components: {
    history,
    Title2,
  },
  async created() {
    this.idappointment = this.$route.params.idappointment;
  },
};
</script>

<style scoped>
.div-oftaHistory{
  min-height: auto;
  margin: auto;
  background-color: #fff;
}
</style>
