<template>
  <section
    class="pdf-content"
  >
    <el-container
      style="width: 90%; margin: auto;"
    >
      <el-main>
        <div class="content-date">
          <div
            style="background-color: #6A41BD;
            width: 95px;
            height: 75px;
            display: flex;
            justify-content: center;
            border-radius: 5px;
            "
          >
            <img
              src="../assets/login-bg.png"
              alt="logo Codet"
              style="width: 75px; height: 75px"
            >
        </div>
          <div class="medical-note">
            <p v-if="appointmentDate.length >= 10"> {{ $dateString(appointmentDate) }}</p>
          </div>
        </div>
        <div class="content-header">
          <div class="content-header-child">
            <h4>Ficha de Identificación</h4>
            <table style="width: 100%;">
              <tbody>
                <tr>
                  <td style="width: 30%">
                    <table style="width: 100%" class="table-style">
                      <tbody>
                        <tr>
                          <td style="width:50%; font-weight: bold">Nombre(s)</td>
                          <td style="width:50%;">{{patientGeneralInfo.name}}</td>
                        </tr>
                        <tr>
                          <td style="width:50%; font-weight: bold">Sexo</td>
                          <td style="width:50%;">{{patientGeneralInfo.gender}}</td>
                        </tr>
                        <tr>
                          <td style="width:50%; font-weight: bold">Edad</td>
                          <td style="width:50%;">{{patientGeneralInfo.birthdate || 'Sin registrar'}}
                            ({{ $ageAsWords(patientGeneralInfo.birthdate) }})</td>
                        </tr>
                        <tr>
                          <td style="width:50%; font-weight: bold">No.Afiliación</td>
                          <td style="width:50%;">{{patientGeneralInfo.afiliacion}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="content-header">
          <div class="content-header-child">
            <h4>Signos vitales</h4>
            <table style="width: 100%;">
              <tbody>
                <tr>
                  <td style="width: 30%">
                    <table style="width: 100%" class="table-style">
                      <tbody>
                        <tr>
                          <td style="width:50%; font-weight: bold">Peso</td>
                          <td style="width:50%;">{{vitalSign.weight}} Kg.</td>
                        </tr>
                        <tr>
                          <td style="width:50%; font-weight: bold">Estatura</td>
                          <td style="width:50%;">{{vitalSign.size}} mts.</td>
                        </tr>
                        <tr>
                          <td style="width:50%; font-weight: bold">F.card</td>
                          <td style="width:50%;">{{vitalSign.heartRate}} por minuto</td>
                        </tr>
                        <tr>
                          <td style="width:50%; font-weight: bold">F.resp</td>
                          <td style="width:50%;">{{ vitalSign.respiratoryRate }} por minuto</td>
                        </tr>
                        <tr>
                          <td style="width:50%; font-weight: bold">Presión Arterial</td>
                          <td style="width:50%;">{{vitalSign.bloodPressure}}</td>
                        </tr>
                        <tr>
                          <td style="width:50%; font-weight: bold">Temperatura</td>
                          <td style="width:50%;">{{ vitalSign.temperature }} ºC</td>
                        </tr>
                        <tr>
                          <td style="width:50%; font-weight: bold">IMC</td>
                          <td style="width:50%;">{{ vitalSign.IMC }} </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="content-header">
          <div class="content-header-child">
            <h4>Padecimiento Actual</h4>
            <table style="width: 100%;">
              <tbody>
                <tr>
                  <td style="width: 30%">
                    <table style="width: 100%" class="table-style">
                      <tbody>
                        <tr>
                          <td style="width:50%;">{{reasonConsult}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <section class="content-header">
          <div class="content-header-child">
            <h4>Optometría</h4>
            <div
              style="width: 95%; font-size: 10px;"
              class="optometria"
            >
              <el-table
              :data="optometriaData"
              type="index"
              :index="indexMethod"
              style="width: 99%; color: #000;"
              >
                <el-table-column
                  width="120"
                  label=" "
                  prop="name"
                />
                <el-table-column
                  label="Ojo Derecho"
                  prop="OD"
                />
                <el-table-column
                  label="Ojo Izquierdo"
                  prop="OI"
                />
              </el-table>
            </div>
            <h4>Refracción</h4>
            <div style="width: 95%;" class="optometria">
              <el-table
              :data="refraction"
              type="index"
              :index="indexMethod"
              style="width: 100%; color: #000000;"
              >
                <el-table-column
                  width="120"
                  label=" "
                  prop="name"
                />
                <el-table-column
                  label="Ojo Derecho"
                  prop="OD"
                />
                <el-table-column
                  label="Ojo Izquierdo"
                  prop="OI"
                />
              </el-table>
            </div>
          </div>
        </section>
        <div class="html2pdf__page-break"/>
        <section class="content-header">
          <div class="content-header-child">
            <h4 style="margin-bottom: 5px">
              Exploración oftalmolóxgica - Segmento Anterior
            </h4>
            <el-table
              :data="anteriorSegment"
              style="width: 98%;"
              class="optometria"
            >
              <el-table-column
                width="120"
                prop="inf"
                label=" "
              />
              <el-table-column
                width="271"
                prop="OD"
                label="Ojo Derecho"
              />
              <el-table-column
                width="271"
                prop="OI"
                label="Ojo Izquierdo"
              />
            </el-table>
            <h4>Exploración oftalmológica - Segmento Posterior</h4>
            <el-table
              :data="posteriorSegment"
              style="width: 98%;"
              class="optometria"
            >
              <el-table-column
              width="120"
                prop="inf"
                label=" "
              />
              <el-table-column
              width="271"
                prop="OD"
                label="Ojo Derecho"
              />
              <el-table-column
              width="271"
                prop="OI"
                label="Ojo Izquierdo"
              />
            </el-table>
          </div>
        </section>
        <div class="html2pdf__page-break"/>
        <div class="content-header">
          <div class="content-header-child">
            <h4>Diagnóstico</h4>
            <el-table
              :data="Diagnostic"
              style=
              "
                width: 98%;
                margin-bottom: 15px;
              "
              border
            >
              <el-table-column
                style="width:70%"
                prop="descriptionOD"
                label="Ojo Derecho"
              />
              <el-table-column
                style="width:10%"
                prop="descriptionOI"
                label="Ojo Izquierdo"
              />
            </el-table>
          </div>
        </div>
        <div class="content-header">
          <div class="content-header-child">
            <h4>Plan</h4>
            <el-table
              :data="plan"
              style=
              "
                width: 98%;
                margin-bottom: 15px;
              "
              border
            >
              <el-table-column
                style="width:70%"
                prop="descriptionOD"
                label="Ojo Derecho"
              />
              <el-table-column
                style="width:10%"
                prop="descriptionOI"
                label="Ojo Izquierdo"
              />
            </el-table>
          </div>
        </div>
        <div class="content-header">
          <div
            class="content-header-child"
            style="font-size:10px"
          >
            <h4>Comentarios</h4>
            <p>{{commments}}</p>
          </div>
        </div>
        <div class="firma" style="text-align: center; margin-top: 100px;">
          ____________________________________
          <div
            class="info-evaluator"
            style="margin:8px 0px 15px 0px; font-size: 10px"
          >
            {{creatorName}}
          </div>
        </div>
        <div
          class="content-header"
          style="border-style: none; text-align: center; font-size: 10px"
        >
          Fundación CODET I.B.P.
          |
          AV. Padre Kino 10159, Zona Urbana Río
          Tijuana, 22010, Tijuana, Baja California
          |
          <br>
          Teléfono
          (664) 682 8370
          <br>
          fundacion@codetvisioninstitute.com
          |
          www.fundacioncodet.org
        </div>
      </el-main>
    </el-container>
  </section>
</template>

<script>
export default {
  name: 'historyOftaComponent',
  props: {
    appointment: {
      type: String,
    },
    printPdf: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vitalSign: {
        weight: '',
        size: '',
        hearRate: '',
        respiratoryRate: '',
        bloodPressure: '',
        temperature: '',
        IMC: '',
      },
      optometriaData:
      [
        {
          name: 'AVSC',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          name: 'AVCC',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          name: 'AVPH',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
      ],
      refraction:
      [
        {
          name: 'Esfera',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          name: 'Cilindro',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          name: 'Eje',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          name: 'Adición',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
      ],
      anteriorSegment:
      [
        {
          inf: 'Globo Ocular',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Parpados',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Anexos',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Motilidad Ocular',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Conjuntiva',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Esclera',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Córnea',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'CA',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Iris',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Gonioscopia',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Cristalino',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
      ],
      posteriorSegment:
      [
        {
          inf: 'Viltreo',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Nervio',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Rel. C/D',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Mácula',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Vasos',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Periferia',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Retina General',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
      ],
      Diagnostic:
      [
        {
          descriptionOD: '',
          descriptionOI: '',
        },
      ],
      plan: [
        {
          descriptionOD: '',
          descriptionOI: '',
        },
      ],
      commments: '',
      isFullProfile: {
        type: Boolean,
        default: true,
      },
      patientGeneralInfo: {
        name: 'N/A',
        gender: 'N/A',
        age: 'N/A',
        afiliacion: 'N/A',
      },
      appointmentDate: '',
      idappointment: '',
      patientId: '',
      creatorId: '',
      creatorName: '',
      reasonConsult: '',
      notasMedicas: false,
      historialQuirurgico: false,
      diagnostico: false,
    };
  },
  methods: {
    async getAppointment() {
      try {
        const response = await this.axios.get(`patient/ophtha/getAppointment/${this.patientId}`);
        if (response) {
          this.idappointment = response.data[0].id;

          this.getDataOptometria();
          this.getDataPatientGeneralInfo();
          this.getdataOftalmologia();
        }
      } catch (error) {
        // this.$errorNotify(error);
      }
    },
    validateControlValue() {
      if (this.controlValue.pdfQuality > 2) {
        this.controlValue.pdfQuality = 2;

        return false;
      }

      if (!this.controlValue.paginateElementsByHeight) {
        this.controlValue.paginateElementsByHeight = 1400;

        return false;
      }

      const paperSizes = 'a4';

      if (!paperSizes.includes(this.controlValue.pdfFormat)) {
        this.controlValue.pdfFormat = 'a4';

        return false;
      }

      if (!this.controlValue.pdfOrientation) {
        this.controlValue.pdfOrientation = 'portrait';

        return false;
      }

      if (!this.controlValue.pdfContentWidth) {
        this.controlValue.pdfContentWidth = '800px';

        return false;
      }

      return true;
    },
    indexMethod(index) {
      return index * 2;
    },
    async getDataPatientGeneralInfo() {
      try {
        const response = await this.axios.get(`patient/ophtha/patientinfoGeneralInfo/${this.appointment}`);
        if (response.data) {
          const { data } = response;

          // asignando data
          this.patientGeneralInfo.name = `${data[0].firstName} ${data[0].lastName}`;
          this.patientGeneralInfo.birthdate = data[0].birthdate;
          this.appointmentDate = data[0].date;
          this.patientGeneralInfo.gender = 'Sin especificar';
          this.patientGeneralInfo.afiliacion = 'Sin especificar';

          const profile = await this.axios.get(`patient/ophtha/patientinfoGeneralInfoPatient/${this.patientId}`);
          if (profile) {
            const dataProfile = profile.data;
            this.patientGeneralInfo.gender = dataProfile[0].gender;
            this.patientGeneralInfo.afiliacion = dataProfile[0].affiliation;
            if (this.patientGeneralInfo.afiliacion === null) {
              this.patientGeneralInfo.afiliacion = 'Sin afiliación';
            }
            if (this.patientGeneralInfo.gender === null) {
              this.patientGeneralInfo.gender = 'Sin especificar';
            }
          }
        }
      } catch (error) {
        // this.$errorNotify(error);
      }
    },
    async getDataOptometria() {
      const patientId = this.$route.params.id;
      try {
        const response = await this.axios.get(`patient/opto/signalvital/${patientId}`);
        if (response.data) {
          // datos de Signos vitales
          const vital = response.data[0];
          this.vitalSign.weight = vital.weight;
          this.vitalSign.size = vital.size;
          this.vitalSign.hearRate = vital.hearRate;
          this.vitalSign.respiratoryRate = vital.respiratoryRate;
          this.vitalSign.bloodPressure = vital.bloodPressure;
          this.vitalSign.temperature = vital.temperature;
          // Calcular IMC
          const peso = this.vitalSign.weight;
          const altura = this.vitalSign.size ** 2;
          const IMC = peso / altura;
          this.vitalSign.IMC = IMC.toFixed(2);

          const response2 = await this.axios.get(`patient/opto/details/${patientId}/${this.idappointment}`);
          // Datos de Optometría
          this.optometriaData[0].OD = response2.data.eyes[0].vaNoCorrection;
          this.optometriaData[1].OD = response2.data.eyes[0].vaWithCorrection;
          this.optometriaData[2].OD = response2.data.eyes[0].refractionPh;

          this.optometriaData[0].OI = response2.data.eyes[1].vaNoCorrection;
          this.optometriaData[1].OI = response2.data.eyes[1].vaWithCorrection;
          this.optometriaData[2].OI = response2.data.eyes[1].refractionPh;
          // Datos de Refracción
          this.refraction[0].OD = response2.data.eyes[0].refractionSphere;
          this.refraction[1].OD = response2.data.eyes[0].refractionCilinder;
          this.refraction[2].OD = response2.data.eyes[0].refractionAxis;
          this.refraction[3].OD = response2.data.eyes[0].refractionAddition;

          this.refraction[0].OI = response2.data.eyes[1].refractionSphere;
          this.refraction[1].OI = response2.data.eyes[1].refractionCilinder;
          this.refraction[2].OI = response2.data.eyes[1].refractionAxis;
          this.refraction[3].OI = response2.data.eyes[1].refractionAddition;

          for (let i = 0; i < this.optometriaData.length; i++) {
            if (this.optometriaData[i].OD === null) {
              this.optometriaData[i].OD = 'Sin alteraciones';
            }
            if (this.optometriaData[i].OI === null) {
              this.optometriaData[i].OI = 'Sin alteraciones';
            }
            if (this.refraction[i].OD === null) {
              this.refraction[i].OD = 'Sin alteraciones';
            }
            if (this.refraction[i].OI === null) {
              this.refraction[i].OI = 'Sin alteraciones';
            }
          }
        }
      } catch (error) {
        // this.$errorNotify(error);
      }
    },
    async getdataOftalmologia() {
      try {
        const patientId = this.$route.params.id;
        const response = await this.axios.get(`patient/ophtha/details/${patientId}/${this.idappointment}`);
        if (response) {
          this.reasonConsult = response.data.reason;
          // OJO IZQUIERDO SEGMENTO ANTERIOR
          const signal = response.data.anteriorSegment[1];
          let ansOD = response.data.anteriorSegment[0];
          if (signal === 'RIGHT') {
            ansOD = signal;
          }
          // OcularGlobule
          if (ansOD.ocularGlobuleComments != null && ansOD.ocularGlobule != null) {
            this.anteriorSegment[0].OD = `${ansOD.ocularGlobule}: ${ansOD.ocularGlobuleComments}`;
          } else if (ansOD.ocularGlobule != null) {
            this.anteriorSegment[0].OD = ansOD.ocularGlobule;
          } else {
            this.anteriorSegment[0].OD = ansOD.ocularGlobuleComments;
          }
          // eyelid
          if (ansOD.eyelidComments != null && ansOD.eyelid != null) {
            this.anteriorSegment[1].OD = `${ansOD.eyelid}:  ${ansOD.eyelidComments}`;
          } else if (ansOD.eyelid != null) {
            this.anteriorSegment[1].OD = ansOD.eyelid;
          } else {
            this.anteriorSegment[1].OD = ansOD.eyelidComments;
          }
          // annexes
          if (ansOD.annexesComments != null && ansOD.annexes != null) {
            this.anteriorSegment[2].OD = `${ansOD.annexes}:  ${ansOD.annexesComments}`;
          } else if (ansOD.annexes != null) {
            this.anteriorSegment[2].OD = ansOD.annexes;
          } else {
            this.anteriorSegment[2].OD = ansOD.annexesComments;
          }
          // ocularMotility
          if (ansOD.ocularMotilityComments != null && ansOD.ocularMotility != null) {
            this.anteriorSegment[3].OD = `${ansOD.ocularMotility}:  ${ansOD.ocularMotilityComments}`;
          } else if (ansOD.ocularMotility != null) {
            this.anteriorSegment[3].OD = ansOD.ocularMotility;
          } else {
            this.anteriorSegment[3].OD = ansOD.ocularMotilityComments;
          }
          // conjuntiva
          if (ansOD.conjunctivaComments != null && ansOD.conjunctiva != null) {
            this.anteriorSegment[4].OD = `${ansOD.conjunctiva}:  ${ansOD.conjunctivaComments}`;
          } else if (ansOD.conjunctiva != null) {
            this.anteriorSegment[4].OD = ansOD.conjunctiva;
          } else {
            this.anteriorSegment[4].OD = ansOD.conjunctivaComments;
          }
          // sclera
          if (ansOD.scleraComments != null && ansOD.sclera != null) {
            this.anteriorSegment[5].OD = `${ansOD.sclera}:  ${ansOD.scleraComments}`;
          } else if (ansOD.sclera != null) {
            this.anteriorSegment[5].OD = ansOD.sclera;
          } else {
            this.anteriorSegment[5].OD = ansOD.scleraComments;
          }
          // cornea
          if (ansOD.corneaComments != null && ansOD.cornea != null) {
            this.anteriorSegment[6].OD = `${ansOD.cornea}:  ${ansOD.corneaComments}`;
          } else if (ansOD.cornea != null) {
            this.anteriorSegment[6].OD = ansOD.cornea;
          } else {
            this.anteriorSegment[6].OD = ansOD.corneaComments;
          }
          // anteriorChamber
          if (ansOD.anteriorChamberComments != null && ansOD.anteriorChamber != null) {
            this.anteriorSegment[7].OD = `${ansOD.anteriorChamber}:  ${ansOD.anteriorChamberComments}`;
          } else if (ansOD.anteriorChamber != null) {
            this.anteriorSegment[7].OD = ansOD.anteriorChamber;
          } else {
            this.anteriorSegment[7].OD = ansOD.anteriorChamberComments;
          }
          // iris
          if (ansOD.irisComments != null && ansOD.iris != null) {
            this.anteriorSegment[8].OD = `${ansOD.iris}:  ${ansOD.irisComments}`;
          } else if (ansOD.iris != null) {
            this.anteriorSegment[8].OD = ansOD.iris;
          } else {
            this.anteriorSegment[8].OD = ansOD.irisComments;
          }
          // GENIOSCOPIA
          if (ansOD.ginioscopyComments != null && ansOD.ginioscopy != null) {
            this.anteriorSegment[9].OD = `${ansOD.ginioscopy}: ${ansOD.ginioscopyComments}`;
          } else if (ansOD.ginioscopy != null) {
            this.anteriorSegment[9].OD = ansOD.ginioscopy;
          } else {
            this.anteriorSegment[9].OD = ansOD.ginioscopyComments;
          }
          // CRISTALINO
          if (ansOD.lensComments != null && ansOD.lens != null) {
            this.anteriorSegment[10].OD = `${ansOD.lens}:  ${ansOD.lensComments}`;
          } else if (ansOD.lens != null) {
            this.anteriorSegment[10].OD = ansOD.lens;
          } else {
            this.anteriorSegment[10].OD = ansOD.lensComments;
          }
          const signal2 = response.data.anteriorSegment[0];
          let ansOI = response.data.anteriorSegment[1];
          if (signal2 === 'LEFT') {
            ansOI = signal2;
          }
          // OJO IZQUIERDO SEGMENTO ANTERIOR
          // OcularGlobule
          if (ansOI.ocularGlobuleComments != null && ansOI.ocularGlobule != null) {
            this.anteriorSegment[0].OI = `${ansOI.ocularGlobule}: ${ansOI.ocularGlobuleComments}`;
          } else if (ansOI.ocularGlobule != null) {
            this.anteriorSegment[0].OI = ansOI.ocularGlobule;
          } else {
            this.anteriorSegment[0].OI = ansOI.ocularGlobuleComments;
          }
          // eyelid
          if (ansOI.eyelidComments != null && ansOI.eyelid != null) {
            this.anteriorSegment[1].OI = `${ansOI.eyelid}:  ${ansOI.eyelidComments}`;
          } else if (ansOI.eyelid != null) {
            this.anteriorSegment[1].OI = ansOI.eyelid;
          } else {
            this.anteriorSegment[1].OI = ansOI.eyelidComments;
          }
          // annexes
          if (ansOI.annexesComments != null && ansOI.annexes != null) {
            this.anteriorSegment[2].OI = `${ansOI.annexes}:  ${ansOI.annexesComments}`;
          } else if (ansOI.annexes != null) {
            this.anteriorSegment[2].OI = ansOI.annexes;
          } else {
            this.anteriorSegment[2].OI = ansOI.annexesComments;
          }
          // ocularMotility
          if (ansOI.ocularMotilityComments != null && ansOI.ocularMotility != null) {
            this.anteriorSegment[3].OI = `${ansOI.ocularMotility}:  ${ansOI.ocularMotilityComments}`;
          } else if (ansOI.ocularMotility != null) {
            this.anteriorSegment[3].OI = ansOI.ocularMotility;
          } else {
            this.anteriorSegment[3].OI = ansOI.ocularMotilityComments;
          }
          // conjuntiva
          if (ansOI.conjunctivaComments != null && ansOI.conjunctiva != null) {
            this.anteriorSegment[4].OI = `${ansOI.conjunctiva}:  ${ansOI.conjunctivaComments}`;
          } else if (ansOI.conjunctiva != null) {
            this.anteriorSegment[4].OI = ansOI.conjunctiva;
          } else {
            this.anteriorSegment[4].OI = ansOI.conjunctivaComments;
          }
          // sclera
          if (ansOI.scleraComments != null && ansOI.sclera != null) {
            this.anteriorSegment[5].OI = `${ansOI.sclera}:  ${ansOI.scleraComments}`;
          } else if (ansOI.sclera != null) {
            this.anteriorSegment[5].OI = ansOI.sclera;
          } else {
            this.anteriorSegment[5].OI = ansOI.scleraComments;
          }
          // cornea
          if (ansOI.corneaComments != null && ansOI.cornea != null) {
            this.anteriorSegment[6].OI = `${ansOI.cornea}:  ${ansOI.corneaComments}`;
          } else if (ansOI.cornea != null) {
            this.anteriorSegment[6].OI = ansOI.cornea;
          } else {
            this.anteriorSegment[6].OI = ansOI.corneaComments;
          }
          // anteriorChamber
          if (ansOI.anteriorChamberComments != null && ansOI.anteriorChamber != null) {
            this.anteriorSegment[7].OI = `${ansOI.anteriorChamber}:  ${ansOI.anteriorChamberComments}`;
          } else if (ansOI.anteriorChamber != null) {
            this.anteriorSegment[7].OI = ansOI.anteriorChamber;
          } else {
            this.anteriorSegment[7].OI = ansOI.anteriorChamberComments;
          }
          // iris
          if (ansOI.irisComments != null && ansOI.iris != null) {
            this.anteriorSegment[8].OI = `${ansOI.iris}:  ${ansOI.irisComments}`;
          } else if (ansOI.iris != null) {
            this.anteriorSegment[8].OI = ansOI.iris;
          } else {
            this.anteriorSegment[8].OI = ansOI.irisComments;
          }
          // GENIOSCOPIA
          if (ansOI.ginioscopyComments != null && ansOI.ginioscopy != null) {
            this.anteriorSegment[9].OI = `${ansOI.ginioscopy}: ${ansOI.ginioscopyComments}`;
          } else if (ansOI.ginioscopy != null) {
            this.anteriorSegment[9].OI = ansOI.ginioscopy;
          } else {
            this.anteriorSegment[9].OI = ansOI.ginioscopyComments;
          }
          // CRISTALINO
          if (ansOI.lensComments != null && ansOI.lens != null) {
            this.anteriorSegment[10].OI = `${ansOI.lens}:  ${ansOI.lensComments}`;
          } else if (ansOI.lens != null) {
            this.anteriorSegment[10].OI = ansOI.lens;
          } else {
            this.anteriorSegment[10].OI = ansOI.lensComments;
          }

          for (let i = 0; i < this.anteriorSegment.length; i++) {
            if (this.anteriorSegment[i].OD === null) {
              this.anteriorSegment[i].OD = 'Sin alteraciones';
            }
            if (this.anteriorSegment[i].OI === null) {
              this.anteriorSegment[i].OI = 'Sin alteraciones';
            }
          }
          // const segPosOD = response.data.posteriorSegment[0];
          const signal3 = response.data.posteriorSegment[1];
          let segPosOD = response.data.posteriorSegment[0];
          if (signal3 === 'RIGHT') {
            segPosOD = signal3;
          }
          // Datos de Segmento Posterior Ojo DERECHO
          // OJO IZQUIERDO SEGMENTO POSTERIOR
          // vitreo
          if (segPosOD.vitreoComments != null && segPosOD.vitreo != null) {
            this.posteriorSegment[0].OD = `${segPosOD.vitreo}:  ${segPosOD.vitreoComments}`;
          } else if (segPosOD.vitreo != null) {
            this.posteriorSegment[0].OD = segPosOD.vitreo;
          } else {
            this.posteriorSegment[0].OD = segPosOD.vitreoComments;
          }
          // nerveComments
          if (segPosOD.nerveComments != null && segPosOD.nerve != null) {
            this.posteriorSegment[1].OD = `${segPosOD.nerve}:  ${segPosOD.nerveComments}`;
          } else if (segPosOD.nerve != null) {
            this.posteriorSegment[1].OD = segPosOD.nerve;
          } else {
            this.posteriorSegment[1].OD = segPosOD.nerveComments;
          }
          // cupDiscComments
          if (segPosOD.cupDiscComments != null && segPosOD.cupDisc != null) {
            this.posteriorSegment[2].OD = `${segPosOD.cupDisc}:  ${segPosOD.cupDiscComments}`;
          } else if (segPosOD.cupDisc != null) {
            this.posteriorSegment[2].OD = segPosOD.cupDisc;
          } else {
            this.posteriorSegment[2].OD = segPosOD.cupDiscComments;
          }
          // maculaComments
          if (segPosOD.maculaComments != null && segPosOD.macula != null) {
            this.posteriorSegment[3].OD = `${segPosOD.macula}:  ${segPosOD.maculaComments}`;
          } else if (segPosOD.macula != null) {
            this.posteriorSegment[3].OD = segPosOD.macula;
          } else {
            this.posteriorSegment[3].OD = segPosOD.maculaComments;
          }
          // vesselComments
          if (segPosOD.vesselComments != null && segPosOD.vessel != null) {
            this.posteriorSegment[4].OD = `${segPosOD.vessel}:  ${segPosOD.vesselComments}`;
          } else if (segPosOD.vessel != null) {
            this.posteriorSegment[4].OD = segPosOD.vessel;
          } else {
            this.posteriorSegment[4].OD = segPosOD.vesselComments;
          }
          // peripheryComments
          if (segPosOD.peripheryComments != null && segPosOD.peripher != null) {
            this.posteriorSegment[5].OD = `${segPosOD.periphery}:  ${segPosOD.peripheryComments}`;
          } else if (segPosOD.periphery != null) {
            this.posteriorSegment[5].OD = segPosOD.periphery;
          } else {
            this.posteriorSegment[5].OD = segPosOD.peripheryComments;
          }
          // retinaComments
          if (segPosOD.retinaComments != null && segPosOD.retina != null) {
            this.posteriorSegment[6].OD = `${segPosOD.retina}:  ${segPosOD.retinaComments}`;
          } else if (segPosOD.retina != null) {
            this.posteriorSegment[6].OD = segPosOD.retina;
          } else {
            this.posteriorSegment[6].OD = segPosOD.retinaComments;
          }

          // Datos de Segmento Posterior Ojo IZQUIERDO
          const signal4 = response.data.posteriorSegment[0];
          let segPosOI = response.data.posteriorSegment[1];
          if (signal4 === 'LEFT') {
            segPosOI = signal4;
          }
          // OJO IZQUIERDO SEGMENTO POSTERIOR
          // vitreo
          if (segPosOI.vitreoComments != null && segPosOI.vitreo != null) {
            this.posteriorSegment[0].OI = `${segPosOI.vitreo}:  ${segPosOI.vitreoComments}`;
          } else if (segPosOI.vitreo != null) {
            this.posteriorSegment[0].OI = segPosOI.vitreo;
          } else {
            this.posteriorSegment[0].OI = segPosOI.vitreoComments;
          }
          // nerveComments
          if (segPosOI.nerveComments != null && segPosOI.nerve != null) {
            this.posteriorSegment[1].OI = `${segPosOI.nerve}:  ${segPosOI.nerveComments}`;
          } else if (segPosOI.nerve != null) {
            this.posteriorSegment[1].OI = segPosOI.nerve;
          } else {
            this.posteriorSegment[1].OI = segPosOI.nerveComments;
          }
          // cupDiscComments
          if (segPosOI.cupDiscComments != null && segPosOI.cupDisc != null) {
            this.posteriorSegment[2].OI = `${segPosOI.cupDisc}:  ${segPosOI.cupDiscComments}`;
          } else if (segPosOI.cupDisc != null) {
            this.posteriorSegment[2].OI = segPosOI.cupDisc;
          } else {
            this.posteriorSegment[2].OI = segPosOI.cupDiscComments;
          }
          // maculaComments
          if (segPosOI.maculaComments != null && segPosOI.macula != null) {
            this.posteriorSegment[3].OI = `${segPosOI.macula}:  ${segPosOI.maculaComments}`;
          } else if (segPosOI.macula != null) {
            this.posteriorSegment[3].OI = segPosOI.macula;
          } else {
            this.posteriorSegment[3].OI = segPosOI.maculaComments;
          }
          // vesselComments
          if (segPosOI.vesselComments != null && segPosOI.vessel != null) {
            this.posteriorSegment[4].OI = `${segPosOI.vessel}:  ${segPosOI.vesselComments}`;
          } else if (segPosOI.vessel != null) {
            this.posteriorSegment[4].OI = segPosOI.vessel;
          } else {
            this.posteriorSegment[4].OI = segPosOI.vesselComments;
          }
          // peripheryComments
          if (segPosOI.peripheryComments != null && segPosOI.peripher != null) {
            this.posteriorSegment[5].OI = `${segPosOI.periphery}:  ${segPosOI.peripheryComments}`;
          } else if (segPosOI.periphery != null) {
            this.posteriorSegment[5].OI = segPosOI.periphery;
          } else {
            this.posteriorSegment[5].OI = segPosOI.peripheryComments;
          }
          // retinaComments
          if (segPosOI.retinaComments != null && segPosOI.retina != null) {
            this.posteriorSegment[6].OI = `${segPosOI.retina}:  ${segPosOI.retinaComments}`;
          } else if (segPosOI.retina != null) {
            this.posteriorSegment[6].OI = segPosOI.retina;
          } else {
            this.posteriorSegment[6].OI = segPosOI.retinaComments;
          }

          for (let i = 0; i < this.posteriorSegment.length; i++) {
            if (this.posteriorSegment[i].OD === null) {
              this.posteriorSegment[i].OD = 'Sin alteraciones';
            }
            if (this.posteriorSegment[i].OI === null) {
              this.posteriorSegment[i].OI = 'Sin alteraciones';
            }
          }
          this.Diagnostic[0].descriptionOD = response.data.posteriorSegment[0].diagnosis;
          this.Diagnostic[0].descriptionOI = response.data.posteriorSegment[1].diagnosis;

          this.plan[0].descriptionOD = response.data.posteriorSegment[0].plan;
          this.plan[0].descriptionOI = response.data.posteriorSegment[1].plan;

          this.commments = response.data.comments;
          this.creatorId = response.data.anteriorSegment[0].evaluatorId;
          this.creator(this.creatorId);
        }
      } catch (error) {
        this.$errorNotify(error);
      }
    },
    async creator(creator) {
      const response = await this.axios.get(`patient/ophtha/creatorInfo/${creator}`);
      if (response) {
        const { firstName } = response.data[0];
        const { lastName } = response.data[0];
        this.creatorName = `${firstName} ${lastName}`;
      }
    },
  },
  async created() {
    this.patientId = this.$route.params.id;
    this.getAppointment();
    // this.getDataOptometria();
    // this.getDataPatientGeneralInfo();
    // this.getdataOftalmologia();
  },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap');
.pdf-content{
  width: 100%;
}
.options {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
.content-header{
  width: 100%;
  margin: auto;
  margin-top: 18px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #FFF;
  border-radius: 10px;
  border: solid 1px rgb(71, 71, 71)
}
h4{
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  font-size: 12px;
  margin-bottom: 5px;
}
.content-header-child{
  margin-left: 15px;
  margin-bottom: 5px;
}
.table-style{
  font-size: 10px;
}
.optometria{
  color: #000000;
  background: #fff;
  border: solid 1px rgb(71, 71, 71);
  border-radius: 8px;
  position: relative;
  margin-bottom: 20px;
}
.content-date{
  font-size: 10px;
  width:100%;
  display:flex;
  justify-content:space-between;

  img {
    width: 100px;
    height: 75px;
  }
}
.medical-note{
  p{
    font-size: 10px;
  }
}
.el-table.el-table--fit.el-table--striped.el-table--enable-row-hover.el-table--enable-row-transition
,.el-table.optometria.el-table--fit.el-table--enable-row-hover.el-table--enable-row-transition,
.el-table.el-table--fit.el-table--border.el-table--enable-row-hover.el-table--enable-row-transition,
.el-table.el-table--fit.el-table--enable-row-hover.el-table--enable-row-transition
{
  font-size: 10px;
}
.cell{
  word-break: break-word;
}
</style>
<style>
.el-table th.is-leaf, .el-table td {
    border-bottom: 1px solid #dbdbdb;
}
.el-table .cell
{
  word-break: normal;
  line-height: 16px;
  padding-right: 0px;
  margin-right: 5px;
}
</style>
